<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")' href="https://github.com/derzz" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "github")' href="https://www.linkedin.com/in/michael-xie1/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' href="mailto:michaelx.10@hotmail.com" target='_blank'>michaelx.10&#64;hotmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
    </div>

</footer>
